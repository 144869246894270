import { useState, useCallback, useEffect } from "react";
import { useBoolean } from "./use-boolean";

function useLoader(initialState) {
    const [progress, setProgress] = useState(initialState);
    const loading = useBoolean(true);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress >= 100) {
                    clearInterval(timer);
                    return 100;
                }

                let diff;
     
                if (oldProgress >= 90) {
                    diff = Math.random() * 0.01;
                }
                else if (oldProgress >= 80) {
                    diff = Math.random() * 0.1;
                }
                else if (oldProgress >= 60) {
                    diff = Math.random() * 2;
                } else if (oldProgress >= 40) {
                    diff = Math.random() * 5;
                } else {
                    diff = Math.random() * 10;
                }

                return Math.min(oldProgress + diff, 100);
            });
        }, 200);

        return () => {
            clearInterval(timer);
        };
    }, [initialState])

    const loaderComplete = useCallback(() => {

        setProgress(100);
        setTimeout(() => {
            setProgress(0);
            loading.onFalse();
        }, 200);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { progress, loading, setProgress, loaderComplete };
}

export default useLoader;
