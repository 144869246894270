import React, { useState } from 'react';

// @mui
import { styled } from '@mui/material/styles';
import { Typography, IconButton, Box, Tooltip, SvgIcon, Dialog } from '@mui/material';
import AmSvg from '../../assets/svgs/amSvg';
import MailIcon from '@mui/icons-material/Mail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { CustomSkeleton } from '../skeleton/skeletonLoader';
import commonMessage from '../../utils/commonMessage.json'
import NetworkError from '../../components/Error';
import FileCopyLineIcon from '../../assets/svgs/icons/file-copy-line';
import { copyToClipBoard } from '../../utils/common';
import GlobalStyle from './globalstyle';

const CardsMainSection = styled('div')(({ theme, highlight }) => ({
  backgroundColor: theme.palette.card.main,
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  padding: '16px 19px',
  width: 'calc(100% - 100% / 1.6 - 7px)',
  borderRadius: "6px",
  border: '1px solid',
  alignItems: 'center',
  justifyContent: 'center',
  borderColor: theme.palette.grey[300],
  transition: 'box-shadow 1.5s ease-in-out',
  ...(highlight && {
    animation: 'highlightEffect 1.5s ease-in-out 2', // Apply shadow animation
  }),

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const ProfileImage = styled('img')(() => ({
  width: '40px',
  height: '40px',
  objectFit: 'cover',
  borderRadius: '50%',
}));

const CardsLeftSection = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  // width: 'calc(100% / 1.1)',
  flex: 1,
  gap: '8px'
}));

const CardsRightSection = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  // width: 'calc(100% - 100% / 1.1)',
  flexDirection: 'row',
  gap: '20px'
}));

const Heading = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[800],
  fontSize: '14px',
  fontWeight: 600,
}));

const ActionButtonSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px'
}));

const AmName = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
  fontSize: '12px',
  fontWeight: 500,
}));

// const ActionButtonDetailsSection = styled('div')(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'row',
//   alignItems: 'center',
//   gap: '10px'
// }));

const CustomIconBuuton = styled(IconButton)(({ theme }) => ({
  height: '16px',
  width: '16px',
  backgroundColor: 'transparent',
  color: theme.palette.grey[1000],
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.grey[1000],
  }
}));

// const Title = styled(Typography)(({ theme }) => ({
//   color: theme.palette.grey[600],
//   fontSize: '14px',
//   fontWeight: 500,
//   whiteSpace: "nowrap",
//   overflow: "hidden",
//   textOverflow: "ellipsis",
//   maxWidth: '95%'
// }));

const ProfileSection = styled(IconButton)(() => ({
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  padding: 0,
  overflow: 'hidden',
}));

const AccountManagerSection = ({ AMName = "", AMprofileUrl, countryCode = "", email = "", mobileNo = "", loader, highlight }) => {

  const [open, setOpen] = useState(false);
  const handleImageClick = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  /*   const handleMailClick = (e) => {
      window.location.href = `mailto:${email}`
    }
  
    const handleMobileClick = (e) => {
      if (isMobile) {
        window.location.href = `tel:${countryCode}${mobileNo}`
      }
    } */

  return (
    <>
      <GlobalStyle />
      {AMName &&
        <CardsMainSection highlight={highlight} >
          <CardsLeftSection>
            {(AMprofileUrl && email !== "prk@sarv.com") ?
              <>
                <ProfileSection disableFocusRipple disableTouchRipple disableRipple>
                  <ProfileImage src={AMprofileUrl} alt='profileImage' onClick={handleImageClick} />
                </ProfileSection>
                <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                  <Box sx={{ textAlign: 'center', padding: '16px' }}>
                    <img
                      src={AMprofileUrl}
                      alt="enlarged profile"
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                        borderRadius: '8px',
                      }}
                    />
                  </Box>
                </Dialog>
              </>
              : <AmSvg />}

            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2px'
            }}>
              <Heading>
                Account Manager
              </Heading>

              <AmName>
                {email === "prk@sarv.com" ? "sales" : AMName}
              </AmName>
            </Box>


            <ActionButtonSection>
              {/*   <ActionButtonDetailsSection onClick={() => handleMailClick()}>

                <CustomIconBuuton>
                  <MailIcon sx={{ width: '16px' }} />
                </CustomIconBuuton>

                <Title >
                  {email === "prk@sarv.com" ? "sales@sarv.com " : email}
                </Title>
              </ActionButtonDetailsSection>

              <ActionButtonDetailsSection onClick={() => handleMobileClick()}>

                <CustomIconBuuton>
                  <LocalPhoneIcon sx={{ width: '16px' }} />
                </CustomIconBuuton>

                <Title>
                  {email === "prk@sarv.com" ? "9111-9111-00" : `+${countryCode}${mobileNo}`}
                </Title>
              </ActionButtonDetailsSection> */}

            </ActionButtonSection>
          </CardsLeftSection>

          <CardsRightSection>
            {/* {(AMprofileUrl && email !== "prk@sarv.com") ? <ProfileSection disableFocusRipple disableTouchRipple disableRipple>
              <ProfileImage src={AMprofileUrl} alt='profileImage' />
            </ProfileSection> : <AmSvg />} */}

            {/* <AmName>
              {email === "prk@sarv.com" ? "sales" : AMName}
            </AmName> */}
            <Tooltip placement="top"
              title={
                <Box sx={{ display: 'flex', gap: '10px', width: 'auto', alignItems: 'center' }}>
                  <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>
                    {email === "prk@sarv.com" ? "sales@sarv.com " : email}
                  </Typography>

                  <CustomIconBuuton onClick={() => copyToClipBoard(email === "prk@sarv.com" ? "sales@sarv.com " : email)} sx={{
                    color: 'grey.100', '&:hover': {
                      color: 'grey.100',
                    }
                  }}>
                    <SvgIcon sx={{ width: '12px', height: '12px' }}>
                      <FileCopyLineIcon color={'currentColor'} />
                    </SvgIcon>

                  </CustomIconBuuton>
                </Box>
              }
            >
              <CustomIconBuuton>
                <MailIcon sx={{ width: '16px' }} />
              </CustomIconBuuton>
            </Tooltip>

            <Tooltip placement="top"
              title={
                <Box sx={{ display: 'flex', gap: '10px', width: 'auto', alignItems: 'center' }}>
                  <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>
                    {email === "prk@sarv.com" ? "9111-9111-00" : `+${countryCode}${mobileNo}`}
                  </Typography>

                  <CustomIconBuuton onClick={() => copyToClipBoard(email === "prk@sarv.com" ? "9111-9111-00" : `+${countryCode}${mobileNo}`)} sx={{
                    color: 'grey.100', '&:hover': {
                      color: 'grey.100',
                    }
                  }}>
                    <SvgIcon sx={{ width: '12px', height: '12px' }}>
                      <FileCopyLineIcon color={'currentColor'} />
                    </SvgIcon>

                  </CustomIconBuuton>
                </Box>
              }
            >
              <CustomIconBuuton>
                <LocalPhoneIcon sx={{ width: '16px' }} />
              </CustomIconBuuton>
            </Tooltip>


          </CardsRightSection >

        </CardsMainSection >}

      {
        loader ? <CardsMainSection>
          <CardsLeftSection>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2px'
            }}>
              <Heading>
                Account Manager
              </Heading>
              <Heading>
                <CustomSkeleton variant={"text"} width={"80%"} height={"18px"} />
              </Heading>

            </Box>
            {/* <ActionButtonSection>

              <ActionButtonDetailsSection>
                <CustomSkeleton variant={"text"} width={"50%"} height={"18px"} />
              </ActionButtonDetailsSection>

              <ActionButtonDetailsSection>
                <CustomSkeleton variant={"text"} width={"50%"} height={"18px"} />
              </ActionButtonDetailsSection>

            </ActionButtonSection> */}

          </CardsLeftSection>
          {/* <CardsRightSection>
            <CustomSkeleton animation="wave" variant="circular" width={80} height={80} />
            <CustomSkeleton variant={"text"} width={"50%"} height={"18px"} />
          </CardsRightSection> */}

        </CardsMainSection> : !AMName && <CardsMainSection>
            {AMName  && <CardsLeftSection>
            <Heading>
              Want to connect with your
              Account Manager
            </Heading>
          </CardsLeftSection>}
          <NetworkError message={commonMessage?.noManagerAssign} /></CardsMainSection>
      }</>
  )
}

export default AccountManagerSection;
