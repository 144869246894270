import React, { useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const FormContainer = styled("div")(({ theme }) => ({
  width: "450px",
  display: "flex",
  background: "#FFFFFF",
  padding: "30px 40px",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10px",
  [theme.breakpoints.down("sm")]: {
    borderRadius: "0px",
    width: "auto",
    padding: "20px 30px",
  },
}));

const TypeAccountForm = ({ handleLoader, nextStep }) => {
  const [accountType, setAccountType] = useState("");
  const navigate = useNavigate();

  const handleAccountTypeChange = (event) => {
    setAccountType(event.target.value);
  };

  const handleSubmit = () => {
    nextStep(accountType);
    if (accountType) {
      if (accountType === "personal") {
        navigate("/register", { state: { accountType: "personal" } });
      } else if (accountType === "business") {
        navigate("/register", { state: { accountType: "business" } });
      }
      // Handle the account type selection, e.g., save it or navigate
      console.log("Selected Account Type:", accountType);
      // Replace with the actual path you want to navigate to
    } else {
      alert("Please select an account type.");
    }
  };

  return (
    <FormContainer>
      <Box
        sx={{
          textAlign: { xs: "center", sm: "start", md: "start", lg: "start" },
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h5" sx={{ color: "#282828" }}>
            Select Account Type
          </Typography>
        </Box>

        <FormControl component="fieldset">
          <FormLabel component="legend">Account Type</FormLabel>
          <RadioGroup
            aria-label="account-type"
            name="account-type"
            value={accountType}
            onChange={handleAccountTypeChange}
          >
            <FormControlLabel
              value="personal"
              control={<Radio />}
              label="Personal"
            />
            <FormControlLabel
              value="business"
              control={<Radio />}
              label="Business"
            />
          </RadioGroup>
        </FormControl>

        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Continue
        </Button>
      </Box>
    </FormContainer>
  );
};

export default TypeAccountForm;
