import { styled } from "@mui/material";

const GlobalStyle = styled('style')`
  @keyframes highlightEffect {
    0% {
      transform: scale(1);
      box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    }
    50% {
      transform: scale(1.05);
      box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.4);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    }
  }
`;


export default GlobalStyle;